$primary-color: #0ca6df;
$sencodary-color: #41ccff;
$dark-primary-color: #0493c6;

$black-color: #000000;
$darkest-color: #30323a;
$dark-grey-color: #565b6e;
$dark-light-grey-color: #5a6e7b;
$grey-color: #8a9fab;
$light-hard-grey-color: #bcccd6;
$light-grey-color: #e1edf5;
$light-color: #fafafa;
$white-color: #ffffff;

$dark-green-color: #4f9800;
$green-color: #63be00;
$light-green-color: #82cb33;
$green-yellow: #00b663;

$dark-red-color: #af0000;
$red-color: #db0000;
$light-red-color: #e23333;

$dark-orange-color: #f08700;
$orange-color: #f49b00;
$light-orange-color: #f6af33;

$dark-waning-color: #c7a11a;
$waning-color: #ffd747;
$light-waning-color: #fff781;

$primary-gradient-color: linear-gradient(135deg, #00cef3 0%, #0098c6 100%);
$primary-gradient-alpha-color-1: linear-gradient(135deg, #00cef3d5, #0098c6d5);
$primary-gradient-alpha-color-2: linear-gradient(135deg, #00cef3b5, #0098c6b5);
$red-gradient-color: linear-gradient(135deg, #db0000, #af0000);
$red-gradient-alpha-color-1: linear-gradient(135deg, #db0000d5, #af0000d5);
$red-gradient-alpha-color-2: linear-gradient(135deg, #db0000b5, #af0000b5);
$green-gradient-color: linear-gradient(135deg, #63be00, #4f9800);
$green-gradient-alpha-color-1: linear-gradient(135deg, #63be00d5, #4f9800d5);
$green-gradient-alpha-color-2: linear-gradient(135deg, #63be00b5, #4f9800b5);
$orange-gradient-color: linear-gradient(135deg, #f08700 20%, #f6af33 100%);
$orange-gradient-alpha-color-1: linear-gradient(135deg, #f49b00d5, #f08700d5);
$orange-gradient-alpha-color-2: linear-gradient(135deg, #f49b00b5, #f08700b5);
$white-alpha-color-1: rgba(255, 255, 255, 0.92);
$white-alpha-color-2: rgba(255, 255, 255, 0.68);

$light-blue-color: #a2e6ff;
$dark-blue-color: #0098c7;

$inactive-grey-color: #eaf0f5;
