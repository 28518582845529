/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/css/all.css';

// @import 'ngx-ui-switch/ui-switch.component.css';

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/font/KrungthaiFast-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

$font-family-base: 'Krungthai Fast', Arial, Helvetica, sans-serif;

@import 'bootstrap';
@import 'variables';

:root {
  scroll-behavior: auto;
}

body {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  position: relative;
}

.btn-primary {
  color: #fff;
  background: $primary-gradient-color;
  border: transparent;
  border-radius: 8px;
}

.btn-primary:active {
  border: transparent;
}

.btn-primary:active:focus {
  box-shadow: unset;
}

.btn-primary:hover {
  border: transparent;
}

.btn-outline {
  @media only screen and (max-width: 768px) {
    display: block;
  }

  display: none;
  color: #fff;
  border-color: $white-color;
  margin: 5px;
  padding: 9px;
  border-radius: 8px;
}

.btn-outline:active:focus {
  box-shadow: unset;
}

.btn-outline:hover {
  color: $white-color;
}

.btn:focus {
  box-shadow: unset;
}

primary:focus {
  box-shadow: unset;
}

a {
  transition: all 0.3s;
  font-weight: 700;
  color: $primary-color;
  text-decoration: none !important;
  // padding-bottom: 0.5rem;

  &:hover {
    color: $sencodary-color;
  }
}

.header-section {
  @media only screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 0;
    white-space: pre-line;
  }

  a {
    min-width: 60px;
  }

  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  // padding-top: 2rem;
  // padding-bottom: 2rem;
  // padding-left: 1rem;
  // padding-right: 1rem;
  box-sizing: border-box;
}

@media screen and (min-width: 743px) {
  // เมื้อจอกว้างกว่า 743 px ซ่อน scroll bar แนวนอน
  :root {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1080px;
    margin-left: 0 auto !important;
    margin-right: 0 auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .categories-container {
    margin-top: 1.5rem;

    owl-carousel-o {
      padding-right: 0 !important;
    }
  }

  .categories-list {
    owl-carousel-o {
      padding-right: 0 !important;
    }

    .owl-item.active,
    .owl-item {
      width: 150px !important;
      min-height: 190px;
    }

    .owl-carousel .owl-item img {
      width: 100px;
      height: 100px;
    }
  }

  .promotion-list {
    .owl-item.active {
      width: 260px !important;
      min-height: 320px;
    }

    owl-carousel-o {
      padding-right: 0 !important;
    }

    .owl-carousel .owl-item img {
      height: 200px;
    }
  }

  .market-list {
    .owl-item {
      width: 260px !important;
    }

    .owl-item.active {
      width: 260px !important;
      min-height: 280;
    }

    owl-carousel-o {
      padding-right: 0 !important;
    }

    .owl-carousel .owl-item img {
      height: 200px;
    }
  }

  .advertisment-list {
    .owl-item,
    .owl-item.active {
      width: 260px !important;
      min-height: 170px;
    }
    owl-carousel-o {
      padding-right: 0 !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-list {
    .owl-stage {
      margin-left: 0rem !important;
    }
  }
}

.promotion-list {
  .owl-theme .owl-nav {
    position: absolute !important;

    @media only screen and (max-width: 576px) {
      display: none;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      top: 20%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1299px) {
      top: 18%;
    }

    @media only screen and (min-width: 1300px) {
      top: 20%;
    }

    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.market-list {
  .owl-theme .owl-nav {
    position: absolute !important;

    @media only screen and (max-width: 576px) {
      display: none;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
      top: 33.5%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1299px) {
      top: 37.5%;
    }

    @media only screen and (min-width: 1300px) {
      top: 37.5%;
    }

    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.advertisment-list {
  .owl-theme .owl-nav {
    position: absolute !important;
    @media only screen and (max-width: 576px) {
      display: none;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      top: 22.5%;
    }
    @media only screen and (min-width: 992px) and (max-width: 1299px) {
      top: 25%;
    }
    @media only screen and (min-width: 1300px) {
      top: 25%;
    }
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &.skeleton {
    .owl-stage {
      margin-left: 0 !important;
    }
  }
  &.search-list {
    .owl-theme .owl-nav {
      position: absolute !important;
      top: 22.5%;

      @media only screen and (min-width: 1200) {
        display: none;
      }
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.owl-next {
  position: absolute;
  right: -1.5rem;
  background: white !important;
  width: 40px;
  height: 40px;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.owl-prev {
  position: absolute;
  left: -1.5rem;
  background: white !important;
  width: 40px;
  height: 40px;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

i.fas {
  margin-top: 0.25rem;
  font-size: 24px;
  color: $primary-color !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: $primary-color;
}

::selection {
  color: white;
  background: $primary-color;
}

*:focus-visible {
  border: none;
}

.ol-zoom.ol-unselectable.ol-control,
.ol-rotate {
  display: none !important;
}

.card-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  background-clip: border-box;
  border: 0;
  border-radius: 8px;
  z-index: 7 !important;
  .map-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    img.cover {
      width: 100%;
      max-width: 67px;
      border-radius: 8px;
      margin-right: 1.25rem;
    }
    @media screen and (min-width: 768px) {
      .tab-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex !important;
        justify-content: space-between;
        align-items: flex-end;
        overflow: hidden;
        &.remove {
          display: none !important;
        }
        .promotion {
          height: 2.5rem;
          margin-left: -9%;
          width: 50%;
          background: $orange-gradient-color;
          color: $white-color;
          transform: skew(55deg);
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          &.hide {
            opacity: 0;
          }
          &.remove {
            display: none !important;
          }
          p {
            i {
              position: absolute;
              margin-top: 0.875rem;
              left: -1.5rem;
              transform: rotate(90deg) translateX(-100%);
              color: $waning-color !important;
            }
            margin-top: 0.625rem !important;
            margin-left: 4.75rem;
            transform: skew(-55deg);
            span.small {
              font-size: 15px;
            }
          }
        }
        .discount {
          width: 75%;
          height: 3.5rem;
          margin-right: -20%;
          background: $orange-gradient-color;
          color: $white-color;
          background: $primary-gradient-color;
          transform: skew(-55deg);
          border-top-left-radius: 10px;
          border-bottom-right-radius: 10px;
          &.hide {
            opacity: 0;
          }
          &.remove {
            display: none !important;
          }
          p {
            margin-top: 0.625rem !important;
            margin-left: 4rem;
            transform: skew(55deg);
            font-size: 24px;
            span.small {
              font-size: 18px !important;
            }
          }
        }
      }
    }
  }
  h3 {
    font-size: 20px;
    color: black;
    font-weight: 700;
    margin-bottom: 0rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 275px;
  }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
      color: black;
      font-weight: 700;
      margin-bottom: 0rem;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 275px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 0 !important;
    flex-direction: column !important;
    align-items: flex-start;
    img.cover {
      min-width: 400px;
      margin-right: 0 !important;
    }
    .card-closer {
      cursor: pointer;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 26px;
      height: 26px;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
        filter: brightness(5);
      }
    }
    h3 {
      margin-top: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 350px;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      margin-bottom: 0rem;
    }
  }
}

.content-for-map {
  padding: 0.75rem;
  span,
  p {
    margin-bottom: 0.25rem;
    font-size: 14px;
    color: $dark-light-grey-color;
  }

  .status {
    font-weight: 700;
    &.open {
      color: $dark-green-color;
    }
    &.close {
      color: $red-color;
      margin-right: 0 !important;
    }
  }

  .facilities {
    margin-top: 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .facility {
      background: #eff6fc;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      img {
        width: 14px;
      }
      span {
        margin-bottom: -0.1rem;
        font-size: 12px;
        color: $dark-light-grey-color;
        cursor: pointer;
      }
      .display-toottips-when-hover {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-grey-color;
        font-size: 14px;
        margin-left: -2px;
        position: relative;
        span {
          margin-top: 0.1rem;
        }
        .tooltip-box {
          display: none;
        }
        .arrow-down {
          display: none;
        }
      }

      .display-toottips-when-hover:hover {
        .tooltip-box {
          display: block;
          width: max-content;
          height: auto;
          background-color: $black-color;
          opacity: 0.8;
          position: absolute;
          bottom: 35px;
          right: -20px;
          border-radius: 5px;
          color: $white-color;
          padding: 10px 15px;
          font-size: 14px;
          line-height: 20px;
        }
        .arrow-down {
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid $black-color;
          opacity: 0.8;
          position: absolute;
          bottom: 27px;
        }
      }
    }
  }

  .address {
    span {
      .distance-display {
        display: none !important;
      }
    }
  }

  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    span {
      font-size: 14px;
      color: $dark-light-grey-color;
      margin-right: 0.75rem;
      margin-bottom: 0;
    }
    .payment {
      border: 1px solid $light-grey-color;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      img {
        width: 14px;
      }
      span {
        margin-bottom: -0.1rem;
        margin-right: -0.1rem;
        font-size: 12px;
        color: $dark-light-grey-color;
        cursor: pointer;
      }
      .display-toottips-when-hover {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark-grey-color;
        font-size: 14px;
        margin-left: -2px;
        position: relative;
        span {
          margin-top: 0.1rem;
        }
        .tooltip-box {
          display: none;
        }
        .arrow-down {
          display: none;
        }
      }

      .display-toottips-when-hover:hover {
        .tooltip-box {
          display: block;
          width: max-content;
          height: auto;
          background-color: $black-color;
          opacity: 0.8;
          position: absolute;
          bottom: 35px;
          right: -20px;
          border-radius: 5px;
          color: $white-color;
          padding: 10px 15px;
          font-size: 14px;
          line-height: 20px;
        }
        .arrow-down {
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid $black-color;
          opacity: 0.8;
          position: absolute;
          bottom: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .content-for-map {
    .address {
      span {
        .distance-display {
          display: inline-block !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .detail {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    // min-height: 275px;
    &.condense {
      min-height: unset !important;
    }

    .tab-container-mobile {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex !important;
      justify-content: space-between;
      align-items: flex-end;
      overflow: hidden;
      .promotion {
        height: 2.5rem;
        margin-left: -9%;
        width: 50%;
        background: $orange-gradient-color;
        color: $white-color;
        transform: skew(55deg);
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        &.hide {
          opacity: 0;
        }
        p {
          i {
            position: absolute;
            margin-top: 0.875rem;
            left: -1.5rem;
            transform: rotate(90deg) translateX(-100%);
            color: $waning-color !important;
          }
          margin-top: 0.625rem !important;
          margin-left: 4.75rem;
          transform: skew(-55deg);
          span.small {
            font-size: 15px;
          }
        }
      }
      .discount {
        width: 75%;
        height: 3.5rem;
        margin-right: -20%;
        background: $orange-gradient-color;
        color: $white-color;
        background: $primary-gradient-color;
        transform: skew(-55deg);
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        &.hide {
          opacity: 0;
        }
        p {
          margin-top: 0.625rem !important;
          margin-left: 4rem;
          transform: skew(55deg);
          font-size: 24px;
          span.small {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}

.view-information {
  margin: 0 0.75rem 0.75rem 0.75rem;
  .border-top {
    border-top: 1px solid $light-grey-color;
    margin-bottom: 1rem;
  }
  a {
    color: $primary-color;
  }
}

@media screen and (max-width: 767px) {
  .ol-overlay-container {
    position: relative;
    pointer-events: auto;
    justify-content: flex-end;
    align-items: flex-end;
    display: contents;
    background-color: red;
    #ol-popup {
      bottom: calc(70px + 2%) !important;
      width: 88% !important;
      left: 6% !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .ol-overlay-container {
    position: relative;
    pointer-events: auto;
    justify-content: flex-end;
    align-items: flex-end;
    display: contents;
    // #ol-popup {
    //   top: 3.5% !important;
    //   left: 3.5% !important;
    // }
  }
}

@media screen and (min-width: 1200px) {
  .ol-overlay-container {
    position: relative;
    pointer-events: auto;
    justify-content: flex-end;
    align-items: flex-end;
    display: contents;
    #ol-popup {
      top: 3% !important;
      left: calc(1.5% + 350px) !important;
    }
  }
}

.ol-overlaycontainer-stopevent {
  z-index: 1 !important;
}

.ol-popup {
  z-index: 2 !important;
}

// ----- [Start] CDK Overlay css class for Generic Modal ----- //
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 43, 94, 0.7);
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 43, 94, 0.7);
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  max-width: 100%;
  max-height: 100%;

  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
}
// ----- [End] CDK Overlay css class for Generic Modal ----- //
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

ul.pagination {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  .page-link {
    cursor: pointer;
    background-color: transparent;
    border: 0;
  }

  .page-item {
    padding: 0.5rem;
    min-height: 36px;

    &.disabled {
      cursor: not-allowed;
    }

    .page-link {
      background-color: transparent;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.5rem;
      color: #000;
    }

    &.active .page-link {
      color: white;
      background-color: $primary-color;
      border-color: $primary-color;
    }

    .page-link:hover {
      color: #000;
      background-color: #eaf0f5;
      border-color: #eaf0f5;
      border-radius: 50%;
    }

    .page-link:focus {
      box-shadow: none !important;
    }

    .page-link:focus-visible {
      box-shadow: none !important;
    }
  }
}

a[aria-label='Previous'],
a[aria-label='Next'] {
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

a[aria-label='Previous'] {
  span {
    display: none !important;
  }

  &::before {
    content: url(/assets/img/icon/left-arrow.svg) !important;
    padding-top: 0.5rem;
  }
}

a[aria-label='Next'] {
  span {
    display: none !important;
  }

  &::before {
    content: url(/assets/img/icon/right-arrow.svg) !important;
    padding-top: 0.5rem;
  }

  &.disabled {
    filter: brightness(1.75) !important;
  }
}

.page-item.disabled {
  filter: brightness(1.75) !important;
}

// new pagination for mobile
@media screen and (max-width: 576px) {
  ul.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      font-size: 18px;
      font-weight: 700 !important;
    }

    .page-link {
      width: 42px !important;
      height: 42px !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
    }

    .page-item {
      &.disabled {
        .page-link {
          background-color: #eff6fc !important;
          filter: brightness(0.56) !important;
          &::before {
            filter: brightness(1.825) !important;
          }
        }
      }
    }

    :not(.disabled) {
      a[aria-label='Previous'] {
        &::before {
          content: url(/assets/img/icon/blue-left-arrow.svg) !important;
        }
      }
      a[aria-label='Next'] {
        &::before {
          content: url(/assets/img/icon/blue-right-arrow.svg) !important;
        }
      }
    }

    span {
      margin: 0 1.75rem 0 1.5rem;
    }
  }

  a[aria-label='Previous'],
  a[aria-label='Next'] {
    width: 42px;
    height: 42px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
    &:hover {
      background-color: transparent;
    }
  }
}

.dropdown-menu {
  // visibility: visible;
  // opacity: 1;
  // transition: all 0.25s ease-in-out;
  overflow-y: scroll;
  width: 100%;
  position: absolute;
  left: -1.85rem !important;
  top: 0.5rem !important;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e1edf5;
  overflow-y: scroll;
  max-height: 300px;
  .dropdown-item {
    color: $dark-light-grey-color;
    border: 0.5px solid #e1edf5;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #eff6fc;
  color: black;
}

.dropdown-item {
  padding: 0.75rem 0.75rem;
}

.cky-btn {
  border-radius: 8px !important;
}

.cky-prefrence-btn-wrapper + div {
  display: none !important;
}

::placeholder {
  color: #8a9fab !important;
  opacity: 1;
}

.skeleton-loader-promotion {
  .loader {
    margin-bottom: 0.5rem !important;
  }
  .loader.circle {
    width: 32px !important;
    height: 32px !important;
    margin: 0 !important;
    margin-right: 0.5rem !important;
  }
}

@media screen and (max-width: 767px) {
  .owl-carousel .owl-stage {
    margin-left: 15px !important;
  }
}

.cwc-change-consent-button {
  background: linear-gradient(135deg, #00cef3 0%, #0098c6 100%);
  border: unset !important;
  border-radius: 8px;
}
.cwc-accept-button {
  background: linear-gradient(135deg, #00cef3 0%, #0098c6 100%);
  border: unset !important;
  border-radius: 8px;
}
.cwc-setting-button {
  color: #0ca6df;
}

.cwc-save-setting-wrapper > button,
.cwc-preference-acceptAll > button {
  background: linear-gradient(135deg, #00cef3 0%, #0098c6 100%);
  border: unset !important;
  border-radius: 8px;
}

.cwc-category-item-choice > div {
  color: #8a9fab !important;
}

.cwc-preference-header > .cwc-preference-acceptAll,
.cwc-category-item-desc > .cwc-category-item-detail-link {
  display: none !important;
} // hide Element

.cwc-preference-main > .cwc-save-setting-wrapper {
  justify-content: center !important;
  button {
    width: 368px;
    height: Hug (48px);
  }

  @media only screen and (max-width: 600px) {
    button {
      width: 100%;
      height: Hug (48px);
    }
  }
}

.skeleton-result-list {
  .filter-box {
    > .header {
      padding: 1.5rem 1rem;
      .loader {
        margin-bottom: 0 !important;
      }
      border-bottom: 1px solid #e1edf5;
    }
  }
  .filter-content {
    padding: 1.5rem;
    .header,
    .filter-list,
    .filter-list-column,
    .banner-list {
      .loader {
        margin-left: 0.5rem;
        margin-bottom: 0.25rem !important;
      }
      .loader.circle {
        margin-right: 0.5rem !important;
        margin-top: 0rem;
        width: 20px !important;
        height: 20px !important;
      }
    }
    .filter-list {
      .item {
        margin-top: 0.25rem;
      }
      .line {
        background-color: #e1edf5;
        height: 1px;
        width: 94%;
        margin: 1.25rem auto;
        margin-bottom: 1.5rem;
      }
    }
    .filter-list-column {
      .item {
        margin-top: 0.25rem;
        .tag {
          margin-right: 0.5rem;
        }
      }
      .line {
        background-color: #e1edf5;
        height: 1px;
        width: 94%;
        margin: 1.25rem auto;
        margin-bottom: 1.5rem;
      }
    }
  }
  .banner-list {
    margin-top: 2rem;
    text-align: center;
    .header {
      margin-left: -1rem;
      margin-bottom: 0.5rem;
    }
    .item {
      margin-top: 0.5rem;
    }
  }
  .product-list {
    margin-top: 0.5rem;
    .item {
      border-radius: 8px;
      border: 1px solid #e1edf5;
    }
    @media screen and (min-width: 768px) {
      .item {
        display: flex;
        flex-direction: row;
        .image {
        }
        .detail {
          padding: 1.5rem 1rem;
          flex: 1;
        }
      }
    }
  }
  .detail {
    padding: 2rem 1rem 0.825rem 1rem !important;
    flex: 1;
    .address {
      margin-top: 0.5rem;
      flex-wrap: wrap !important;
      align-items: center;
      .vertical-line {
        margin-right: 8px;
        width: 1px;
        height: 14px;
        background-color: #e1edf5;
      }
      .address-detail {
        .loader {
          margin-bottom: 0 !important;
        }
      }
    }
    .facility {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .loader.circle {
        width: 20px;
        height: 20px;
      }
      .loader {
        margin-bottom: 0 !important;
      }
    }
    .facility-list {
      .facility-list-item {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;
        .loader.circle {
          width: 20px;
          height: 20px;
        }
      }
    }
    .payment-method {
      padding-top: 1.25rem;
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: center !important;
      .loader {
        margin-bottom: 0 !important;
      }
      .payment-method-item {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;
        .loader.circle {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .pagination {
      padding-top: 1.25rem;
    }
  }
  .pagination {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    .loader {
      margin-bottom: 0 !important;
    }
    .loader.circle {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.button-pannel {
  width: 48%;
  :first-child {
    margin-right: 16px;
  }
  .loader {
    width: 100%;
  }
}
